<template>
<div class="object-contacts">
    <p class="object-contacts__text object-contacts__text--small">
        <b>ИНН: </b>{{ inn }}
    </p>
</div>
</template>

<script lang="js">
export default {
  name: 'CardInn',
  props: {
    inn: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
</style>
