<template>
    <div class="object-contacts" v-if="email">
        <img src="@/assets/images/svg/mail.svg" alt="" class="object-contacts__icon">
        <a :href="`mailto:${email}`" class="object-contacts__text object-contacts__text--pink">
            {{ email }}
        </a>
    </div>
</template>

<script lang="js">
export default {
  name: 'CardEmail',
  props: {
    email: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
