<template>
    <div class="object-description" v-if="description">
        <p class="object-title">Описание</p>
        <p class="object-title__small" v-html="formatDescription(description)"></p>
    </div>
</template>

<script lang="js">
export default {
  name: 'CardDescription',
  props: {
    description: {
      type: String,
      required: true
    }
  },
  methods: {
    formatDescription(description) {
      if (description) {
        return description.replace(/\n/g, '<br>');
      }
      return '';
    }
  }
}
</script>

<style scoped></style>
