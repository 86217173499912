<template>
    <div class="object-description">
        <p class="object-title">Тип партнера</p>
        <p class="object-title__small">{{ type }}</p>
    </div>
</template>

<script lang="js">
export default {
  name: 'CardPartnerType',
  props: {
    type: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
