<template>
    <div class="object-contacts" v-if="address">
        <img src="@/assets/images/svg/location.svg" alt="" class="object-contacts__icon">
        <p class="object-contacts__text">{{ address }}</p>
    </div>
</template>

<script lang="js">
export default {
  name: 'CardAddress',
  props: {
    address: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped></style>
