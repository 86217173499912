<template>
<div class="object-contacts" v-if="phone_1 || phone_2">
    <img src="@/assets/images/svg/phone.svg" alt="" class="object-contacts__icon">
    <p class="object-contacts__text" v-if="phone_1">
        <a :href="`tel:${phone_1}`" class="object-contacts__text object-contacts__text--pink">
            {{ formatPhoneNumber(phone_1) }}
        </a>
    </p>
    <p class="object-contacts__text" v-if="phone_2">
        <a :href="`tel:${phone_2}`" class="object-contacts__text object-contacts__text--pink">
            {{ formatPhoneNumber(phone_2) }}
        </a>
    </p>
</div>
</template>

<script lang="js">
export default {
  name: 'CardPhone',
  props: {
    phone_1: {
      type: String,
      required: true
    },
    phone_2: {
      type: String,
      required: true
    }
  },
  methods: {
    formatPhoneNumber(phoneNumber) {
      // Удаляем все символы, кроме цифр
      const cleaned = `${phoneNumber}`.replace(/\D/g, '');

      // Применяем маску для международных номеров
      if (cleaned.length === 12) {
        return `+${cleaned.slice(0, 3)} (${cleaned.slice(3, 6)})-${cleaned.slice(6, 9)}-${cleaned.slice(9, 12)}`;
      }

      if (cleaned.length === 11) {
        return `+${cleaned.slice(0, 1)} (${cleaned.slice(1, 4)})-${cleaned.slice(4, 7)}-${cleaned.slice(7, 11)}`;
      }

      if (cleaned.length === 10) {
        return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
      }

      // Если номер не соответствует стандартным форматам
      return phoneNumber;
    }
  }
}
</script>

<style scoped>
</style>
