<template>
    <div>
        <div class="object-description" v-for="item in groupList" :key="item.name">
            <p class="object-title">{{ item.name }}</p>
            <p class="object-title__small object-title__no-mb" v-for="(product, index) in item.products" :key="product">
                <span v-if="item.expand || index < 4">{{ product }}</span>
            </p>
            <p class="filters__btn" v-if="!item.expand && item.products.length > 4" @click="item.expand = true">
                Показать все
                <img src="@/assets/images/svg/chevron-right.svg" alt="">
            </p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CardProducts',
  props: {
    groups: {
      type: Array,
      required: true
    }
  },
  computed: {
    groupList() {
      const groupMap = {
        ws: 0,
        bs: 1,
        fs: 2,
        is: 3
      };

      return [...this.groups].sort((a, b) => {
        const aKey = a.code.toLowerCase();
        const bKey = b.code.toLowerCase();
        return groupMap[aKey] - groupMap[bKey];
      });
    }
  }
}
</script>

<style scoped></style>
